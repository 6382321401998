import PropTypes from 'prop-types';
var WP_PAGE_PROP_TYPES = {
  blocks: PropTypes.array,
  headerData: PropTypes.object,
  footerData: PropTypes.object,
  masterPageBlocks: PropTypes.array
};
var DEFAULT_WP_PAGE_PROPS = {
  blocks: [],
  headerData: undefined,
  footerData: undefined,
  masterPageBlocks: []
};
export { WP_PAGE_PROP_TYPES, DEFAULT_WP_PAGE_PROPS };